.logo-box {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    height: 60px;

    img {
        height: 100%;
        width: auto;
    }
}

footer {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 25px;

    .container-fluid {

        background: white;
        padding: 25px;


        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-column-gap: 10px;

        img {
            max-width: 100%;
            height: auto;
        }

        .custom.sponsor.haupt,
        .custom.sponsor.mitglieder {
            grid-column: span 2;
        }

        .custom.sponsor.partner {
            grid-column: span 4;
        }
    }

    .social {
        display: flex;
        align-items: center;
        @extend .logo-box;

        a img {
            margin-right: 25px;
            height: 50px;
            width: auto;
            display: block;
        }
    }

    .haupt {
        .haupt {
            @extend .logo-box;
        }
    }

    .custom.sponsor.partner {
        .row-fluid {
            display: flex;
            flex-wrap: wrap;

            .span3 {
                flex-basis: 25%;

                a {
                    border-top: 1px solid black;
                    margin-left: 5px;
                    margin-right: 5px;
                    display: block;
                }

                &:nth-child(11),
                &:nth-child(12),
                &:nth-child(13),
                &:nth-child(14) {
                    a {
                        border-bottom: 1px solid black;
                    }
                }


                img {
                    max-height: 100%;
                    width: auto;
                    padding: 5px;

                }
            }
        }
    }
}


body {
    background-color: #fff;
}