@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url(../public/fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url(../public/fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url(../public/fonts/Roboto-Bold.ttf);
}

h1 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 32px;
  margin: 0;
  line-height: 2;
  margin-bottom: 10px;
}

#root {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.App {
  height: 100%;
}

h1 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 48px;
  margin: 0;
  line-height: 1.5;
}

h3 {
  margin-bottom: 5px;
}

header {
  padding: 15px;
  @media screen and (min-width: 768px) {
    padding: 30px;
  }
}

header img {
  max-width: 150px;
  @media screen and (min-width: 768px) {
    max-width: 300px;
  }
}

.add-new {
  padding-top: 60px;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;
  padding-left: 10px;
  padding-right: 10px;
}

p {
  font-family: 'Roboto';
  margin: 0;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.45;
  color: #333;
  font-size: 21px;
}

a {
  font-weight: 500;
  color: #4f4f4f;
  text-decoration: none;
  font-style: italic;
}
