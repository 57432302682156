.boerse-item {
    box-shadow: 0 0 50px #ccc;
    min-height: 300px;
    padding: 30px 20px;
    position: relative;
    margin-bottom: 45px;
    line-height: 1.3;
    background-color: #fff;
    color: #333;
    background-color: #eee;
    font-size: 18px;

    @media (min-width: '600px') {
        padding: 60px 60px;
    }

    h2 {
        margin-top: 10px;
    }

    h3 {
        margin-bottom: 10px;
    }

    &__tag {
        background-color: rgba(41, 122, 106, 0.2);
        color: #297A6A;
        border-radius: 20px;
        line-height: 2;
        padding-left: 10px;
        padding-right: 10px;
        border: 2px solid #297A6A;
        display: inline-block;
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: bold;

        &--biete {
            background-color: rgba(167, 46, 103, 0.2);
            color: #A72E67;
            border-color: #A72E67;
        }

        &--date {
            margin-left: 5px;
            background-color: rgba(105, 105, 105, 0.2);
            color: #696969;
            border-color: rgb(105, 105, 105)
        }
    }

    &__organisation {
        font-weight: 500;
        margin-bottom: 10px;
    }

    .ausbildung {
        margin: 0;
        padding-left: 20px;
    }

    .visibility-button {
        position: absolute;
        right: 30px;
        top: 25px;
        padding: 5px;
        border: 1px solid;
        border-radius: 5px;
        cursor: pointer;
    }
}

.language-switcher {
    a {
        padding-right: 10px;
        text-decoration: underline;
        padding-left: 10px;

        &:first-child {
            padding-left: 0;
        }
    }
}