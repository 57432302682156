.login-form {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}

.input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.columns-2 {
  display: flex;
  justify-content: space-between;
}

.columns-2 {
  .input-wrapper {
    width: calc(50% - 5px);
  }
}