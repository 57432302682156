.header {
    border-bottom: 1px solid #d8d8d8;
    background-color: white;

    &__content {
        max-width: 1080px;
        margin-left: auto;
        margin-right: auto;
        font-weight: 500;
        font-size: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}