/* Select Button */

.select-button {
  overflow: hidden;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid #0a82c5;
  margin-bottom: 15px;

  input[type='radio'] {
    display: none;

    &:checked+label {
      background-color: #0a82c5;
      color: #fff;
    }
  }

  label {
    border-radius: 0;
    padding: 16px 32px;
    border: none;
    font-size: 18px;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    background: transparent;
    color: #0a82c5;
    display: inline-block;
    cursor: pointer;
  }
}